//LAYOUT
export const SHOW_TOASTER = "SHOW_TOASTER";
export const HIDE_TOASTER = "HIDE_TOASTER";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

//SETUP_PAGE
export const UPDATE_SETUP = "UPDATE_SETUP";
export const IS_FIRST_TIME_SETUP = "IS_FIRST_TIME_SETUP";

//PLAY PAGE
export const CONNECT = "CONNECT";
export const CONNECT_START = "CONNECT_START";
export const CONNECT_STOP = "CONNECT_STOP";
